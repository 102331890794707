import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Flex, Text } from "rebass"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import ContentModules from "../components/page/content-modules"

const TopContainer = styled(Flex)`
  ${(props) => props.theme.bp.mobile} {
    height: 300px;
    width: 100%;
  }

  ${(props) => props.theme.bp.tablet} {
    height: 400px;
    width: 100%;
  }

  ${(props) => props.theme.bp.desktop} {
    height: 500px;
    width: 80%;
  }

  padding-left: 35px;
  padding-right: 35px;

  position: relative;
  text-align: right;

  div {
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22pt;
    height: 50px;
    width: 600px;
    position: absolute;

    right: 35px;
    bottom: 50px;
  }
`

const PageTitle = styled(Text)`
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  line-height: 22pt;
  text-transform: uppercase;

  ${(props) => props.theme.bp.mobile} {
    font-size: 12px;
    padding-left: 10px;
  }

  ${(props) => props.theme.bp.tablet} {
    font-size: 16px;
    padding-left: 10%;
  }

  ${(props) => props.theme.bp.desktop} {
    padding-left: 10%;
    font-size: 22px;
  }
`

const PageTemplate = ({ data, location }) => {
  let { page } = data

  return (
    <Layout isCherishedObjects={page.isCherishedObjects}>
      <Helmet title={page.title} />
      {!["About", "Homepage"].includes(page.title) && <TopContainer />}
      {!["About", "Homepage"].includes(page.title) && (
        <PageTitle mb={5}>{page.title}</PageTitle>
      )}
      <ContentModules allModules={page.contentModules} />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...PageContent
    }
  }
`
